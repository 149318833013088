export default {
  common: {
    email: "Email",
    contactAdministrator: "Oops! Something went wrong. Please contact the administrator.",
    search: "Search",
    approve: "Approve",
    approved: "Approved",
    pending: "Pending",
    viewProfile: "View profile",
    editInfo: "Edit info",
    saveChanges: "Save changes",
    cancel: "Cancel",
    apply: "Apply",
    clear: "Clear",
    back: "Back",
    confirmation: "Confirmation",
    upload: "Upload",
    readMore: "Read more",
    readLess: "Read less",
    showArchived: "Show archived",
    quantromContactDetails: "Need further assistance? Contact us:",
  },
  authentication: {
    logInTo: "Log In to",
    password: "Password",
    logIn: "Log In",
    forgetPassword: "Forgot your password?",
    backToLogIn: "Back to log in",
    credentialsWrong: "These credentials do not match our records",
    twoFactor: {
      setupTwoStepVerification: "Set-up 2-factor authentication",
      step2: "Step 2/2",
      scanImage: "Scan the image below with the Google Authentication app on your  phone. ",
      enterSixDigits: "Enter the six-digit code from application",
      afterScanning:
        "After scanning the image, the app will display a six-digit code that you can enter below:",
      scanningProblems:
        "If you have problems with the scanning, please insert the following code in Google Authentication app:",
      twoStepVerification: "2-Step Verification",
    },
    resetPassword: {
      resetPassword: "Reset password",
      enterEmail:
        "Enter the email address associated with your account and we’ll send you a link to reset your password.",
      sendLink: "Send link",
    },
    resetPasswordConfirmation: {
      verifyEmail: "Verify your email address",
      sendEmail:
        "You’re almost done! We’ve sent you an email with instructions on how to reset your password.",
    },
    changePassword: {
      changePassword: "Change password",
      newPassword: "New password",
      passwordConfirmation: "Password confirmation",
    },
    changePasswordConfirmation: {
      passwordChanged: "Password changed!",
      passwordChangedSuccessfully:
        "Your password has been changed successfully. Use your new password to log in.",
      goToLogin: "Go to login",
    },
    finaliseAccount: {
      finaliseAccountSetup: "Finalise account setup",
      stepOneFromTwo: "Step 1/2",
      nextStep: "Next step",
      errorMail: "Mail is not matching token!",
    },
    newPassword: {
      passwordComplexity: {
        weak: "Password complexity: Weak",
        medium: "Password complexity: Medium",
        strong: "Password complexity: Strong",
      },
      passwordContain: "Password must contain:",
      sixChar: "at least 6 characters",
      eigthChar: "at least 8 characters",
      oneUpprCase: "at least 1 upper case character",
      ourRecommandation: "Our recommandation:",
      oneNumber: "At least one number",
      oneSpecialChar: "At least one special character (e.g. {'@'}!#%$)",
    },
  },
  investors: {
    menu: {
      dashboard: "Dashboard",
      portfolio: "Portfolio",
      reports: "Reports",
      myAccount: "My account",
      inbox: "Inbox",
      logOut: "Log out",
    },
    avatar: {
      viewAccount: "View account",
    },
    dashboard: {
      investmentStats: {
        totalHoldings: "Total holdings",
        portfolioYtdPerformance: "Portfolio YTD performance",
        assetSummary: "Asset summary",
        assetQuantity: "Asset quantity",
        assetPrice: "Last asset price",
        return: "Total return",
        currentHoldings: "Current Holdings",
        totalSubscriptionNotes: "Total QP2PL Notes Subscribed",
        totalRedemptionNotes: "Total QP2PL Notes Redeemed",
        totalNotes: "Current QP2PL Notes",
        asset: "Asset",
        lastPrice: "Last price",
      },
      investmentsDataTable: {
        history: "History",
        lastNumberMonths: "last {months} months",
        checkWallet1: "Check your",
        checkWallet2: "Portfolio",
        checkWallet3: "for the full overview",
        date: "Date",
        dealingDate: "Dealing date",
        assetType: "Asset type",
        quantity: "Quantity",
        assetPrice: "Asset price",
        value: "Value",
        actionType: "Action type",
        status: "Status",
        noDataFound: "No data found",
        dataLoading: "Data loading. Please wait",
      },
      lastSubscription: {
        lastSubscription: "Last subscription",
        date: "Date",
        status: "Status",
        newSubscription: "New subscription",
        redemption: "Redemption",
      },
      latestNews: {
        latestNews: "Lastest news",
        readArticle: "Read article",
      },
      portfolio: {
        portfolio: "Portfolio",
        quantromLending: "Quantrom P2P Lending PPN ",
        year: "Year",
        lastTwelveMonths: "Last 12 months",
        all: "All",
        allStats: "Lifetime performance",
        monthRange: "From {minMonth} till {maxMonth}",
        currentPortfolio: "Current portfolio",
      },
      reports: {
        reports: "Reports",
        belowDocumentsReturnsGenerated:
          "In the below documents you can read about all of the details regarding how your returns are generated.",
        btnPersonalReports: "Personal reports",
        btnMonthlyReports: "Monthly reports",
        btnYearlyReports: "Yearly reports",
        btnNewsletters: "Newsletters",
        showAllReports: "Show All",
        quantromPeerToPeerReports: "Quantrom peer to peer lending reports",
      },
      account: {
        myAccount: "My account",
        personalInformation: "Personal information",
        title: "Title",
        firstName: "First name",
        lastName: "Last name",
        ssn: "Social Security Number",
        email: "Email",
        mobile: "Mobile",
        phone: "Phone",
        otherNationality: "Other nationality",
        investorSince: "Investor since",
        addressInfo: "Address information",
        address: "Address",
        zip: "ZIP",
        city: "City",
        country: "Country",
        countryOfResidence: "Country of residence",
        bankingInfo: "Banking information",
        bank: "Bank",
        ibanNumber: "IBAN Number",
        bicCode: "Bic Code",
        kycDocument: "KYC Document",
        kycDocuments: "KYC Documents",
        newDocuments: "New Documents",
        kycDocumentApproved: "Approved KYC documents",
        kycDocumentUploaded: "Uploaded KYC documents",
        dealDocuments: "Deal documents",
        privateDocuments: "Private documents",
        companyInformation: "Company Information",
        companyName: "Company name",
        companyNumber: "Company number",
        primaryContactPerson: "Primary contact person",
        primarySignatory: "Primary signatory",
        signatory: "Signatory",
        name: "Name",
        incomeSourceAndWealth: "Source of income and wealth",
        reasonForSubscription: "Reason for subscription",
        quantromAwarness: "How you became aware of Quantrom",
        sourceOfWealth: "Source of wealth",
        industryAndOccupation: "Industry and occupation",
        purposeOfInvestment: "Business activity",
        reasonSubscription: "Reason for subscription",
        sourceOfIncomeText: "Source of wealth",
      },
      notifications: {
        inbox: "Inbox",
        btnNewMessages: "Unread messages",
        btnOldessages: "Read messages",
        btnMarkedAsRead: "Marked as read",
        markAllRead: "Mark all as read",
        markAllUnread: "Mark all as unread",
        markAsRead: "Mark as read",
        markAsUnread: "Mark as unread",
      },
    },
  },
  admin: {
    approveTransfer: "Approve transfer",
    menu: {
      dashboard: "Dashboard",
      investors: "Investors",
      controlPanel: "Control panel",
    },
    dashboard: {
      tabs: {
        newInvestors: "New Investors",
        subscriptions: "Subscriptions",
        redemption: "Redemptions",
      },
      newInvestors: {
        dataTable: {
          name: "Name",
          entity: "Individual/company",
          createdAt: "Initial Investment Date",
          amount: "Initial Investment Amount",
          kyc: "KYC",
          contract: "Contract",
          subscription: "Subscription",
          actions: "Actions",
        },
      },
      newSubscriptions: {
        subscriptionPending: "Subscription pending",
        noteAttribution: "Note attribution",
        openSubscriptionModal: "Open subscription",
        totalSubscriptions: "Total subscriptions",
        totalTransfers: "Total transfers received",
        totalTransfersReceiving: "Total transfers receiving",
        dataTable: {
          name: "Name",
          entity: "Individual/company",
          createdAt: "Subscription date",
          dealingDate: "Dealing date",
          amount: "Amount",
          action: "Action",
        },
      },
      newRedemptions: {
        redemptionPending: "Redemptions pending",
        redemptionCompleted: "Redemptions completed",
        approveTransfer: "Approve Transfer",
        openRedemptionModal: "Open Redemption",
        totalRedemptions: "Total redemptions",
        dataTable: {
          name: "Name",
          entity: "Individual/company",
          createdAt: "Redemption date",
          dealingDate: "Dealing date",
          amount: "Amount",
        },
      },
    },
    investors: {
      tabs: {
        individuals: "Individuals",
        companies: "Companies",
      },
      customers: {
        dataTable: {
          name: "Name",
          externalId: "External ID",
          email: "Email",
          currentPortfolio: "Current portfolio value",
        },
      },
      companies: {
        dataTable: {
          name: "Name",
          phone: "Phone",
          email: "Email",
          currentPortfolio: "Current portfolio value",
        },
      },
    },
    profile: {
      profile: "Profile",
      reset2Fa: "Reset 2FA",
      deleteInvestor: "Archive investor",
      kyc: "KYC",
      contract: "Contract",
      subscriptions: "Subscriptions",
      redemptions: "Redemptions",
      userAccount: "User account",
      createuser: "Create user",
      notifications: "Notifications",
      viewLogs: "View logs",
    },
    controlPanel: {
      tabs: {
        assetType: "QP2PL PPN",
        reports: "Reports",
        globalNotifications: "Global notifications",
      },
      assetType: {
        price: "Price",
        action: "Action",
        newNotePrice: "New note price",
        notePriceHistory: "Note price history",
        processSubscriptionsAndRedemptions: "Process subscriptions and redemptions",
        dataTable: {
          date: "Date",
          price: "Price",
          updateBy: "Update by",
          action: "Action",
          editPrice: "Edit price",
        },
      },
      reports: {
        monthlyReport: "Monthly report",
        yearlyReport: "Yearly report",
        newsletters: "Newsletters",
        otherReports: "Other reports",
        personalReports: "Personal reports",
        generatePersonalReports: "Generate reports",
        btnUnpublished : "Unpublished reports",
        btnPublished : "Published reports",
        dataTable: {
          date: "Date",
          name: "Name",
          updateBy: "Update by",
          actions: "Actions",
        },
        reportOnNoteHolders: "Report on note holders",
        publishSelected: "Publish selected",
        deleteSelected: "Delete selected",
        selected: "Selected",
        of: "of",
        toastPublishedReports: "{count} report(s) have been published",
        toastDeletedReports: "{count} report(s) have been deleted",
      },
      notifications: {
        newNotification: "New notification",
      },
    },
  },
  modals: {
    updateNotePrice: {
      updateNotePrice: "Update note price for",
      processSubscriptionsAndRedemptions: "Process subscriptions and redemptions for {date}",
      processTransactions: "Process transactions",
      currentPrice: "Current QP2PL PPN price: {currentNotePrice}€",
      newPrice: "New QP2PL PPN price",
      confirmDialogMsg: "Are you sure you want to proceed with updating the note price?",
      dataTable: {
        notes: "notes",
        notePrice: "Note price",
        subscriptionFee: "Subscription Fee",
        type: "Subscription/Redemption",
      },
    },
    uploadReport: {
      report: "report",
      uploadTypeReport: "Upload {type}",
      reportName: "{type} name",
      uploadReport: "Upload report",
    },
    sendNotification: {
      sendGlobalNotification: "Send global notification",
      subject: "Subject",
      message: "Message",
      sendNotification: "Send notification",
    },
    uploadKycDocument: {
      uploadKycDocument: "Upload KYC document",
      kycDocument: "Document name",
      kycDocumentUpload: "Upload document",
      document: "Document",
    },
    uploadPrivateDocument: {
      uploadPrivateDocument: "Upload private document",
      documentName: "Document name",
      document: "Document",
      privateDocumentUpload: "Upload document",
    },
    newSubscription: {
      newSubscription: "New subscription",
      enterAmount: "Enter the amount you want to subscribe",
      amount: "Amount",
      amountLowerCase: "amount",
      submitSubscription: "Submit subscription",
      kycMethod: "KYC method",
      confirmDialogHeader: "Confirm subscription",
      confirmDialogMsg: "Submit a new subscription with {amount}?",
    },
    newRedemption: {
      newRedemption: "New redemption",
      enterAmount: "Enter the amount you want to redeem",
      amount: "Amount",
      amountLowerCase: "amount",
      submitRedemption: "Submit redemption",
      minimumAmountWithFee: "Minimum redemption is EUR 5,000. Redemption fee is EUR 50.",
      currentAmount: "Total holdings",
      minimumHolding: "Minimum holding*",
      confirmNoteRedeem:
        "I hereby confirm, that if the value of my remaining number of Profit Participation Notes becomes less than EUR 5,000, my remaining number of Notes will also be redeemed.",
      confirmDialogHeader: "Confirm redemption",
      confirmDialogMsg: "Submit a new redemption with {amount}?",
    },
    generatePersonalReports: {
      generatePersonalReports: "Generate personal reports",
      selectRange: "Select periode",
      submitGenerateReport: "Generate reports",
      confirmDialogMsg:
        "Are you sure you want to proceed with creating the personal reports for all selected investors in the period from {from} till {to}?",
      dataTable: {
        name: "Name",
        entity: "Individual/company",
      },
    },
    audits: {
      dataTable: {
        title: "Investor logs",
        createdAt: "Created",
        entity: "Activity",
        event: "Event",
        oldValues: "Old values",
        newValues: "New values",
        user: "User",
      },
    },
    dealingDate: {
      setDealingDate: "Set dealing date",
      confirmDialogHeader: "Confirm dealing date",
      confirmDialogMsg: "Set dealing date on {date}?",
      dealingDateAt: "set on",
      transferredAt: "Transfer received on",
      dealingDate: "Dealing date",
      redemptionProcessedOn: "Redemption processed on",
    },
    approveSubscriptionTransfer: {
      approveTransfer: "Approve transfer",
      confirmDialogHeader: "Approve transfer",
      confirmDialogMsg: "Approve transfer of {amount}?",
    },
    reportOnNoteHolders: {
      reportOnNoteHolders: "Report on note holders",
      previousBalanceSheet: "Previsous BalanceSheet Date",
      subscriptions: "Subscriptions (notes)",
      redemptions: "Redemptions (notes)",
      balanceSheet: "BalanceSheet Date",
      totals: "Totals"
    },
  },
  dialogs: {
    approveKycDialog: "Do you want to approve KYC?",
    approveContractDialog: "Do you want to approve contract?",
    approveSubscriptionDialog: "Do you want to approve subscription?",
    createUserDialog: "Do you want to create the user and send activation mail?",
    resetPasswordDialog: "Do you want to send password reset mail to user?",
    resetTwoFactorDialog: "Do you want to reset Two Factor Authentication for user?",
    approveTransferDialog: "Do you want to approve this transfer?",
    cancelSubscriptionDialog: "Do you want to cancel this subscription?",
    cancelRedemptionDialog: "Do you want to cancel this redemption?",
    approveRedemptionDialog: "Do you want to approve this redemption?",
    deleteInvestor: "Do you want to archive this investor?",
    approveDocument: "Do you want to approve this document",
    deleteDocument: "Do you want to delete this document",
    subscription: "Subscription",
    contractNotSigned: "Contract has not yet been signed.",
    redemption: "Redemption",

    deleteRecord: "Do you want to delete this record?",
    deleteSelectedRecords: "Do you want to delete selected record(s)?",
  },
};
