import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import adminRoutes from "@/router/routes/adminRoutes";
import {
  ROUTE_CHANGE_PASSWORD,
  ROUTE_FINALISE_ACCOUNT,
  ROUTE_HOME_ADMIN,
  ROUTE_HOME_INVESTORS,
  ROUTE_RESET_PASSWORD,
  ROUTE_RESET_PASSWORD_ADMIN,
  ROUTE_SIGN_IN,
  ROUTE_SIGN_IN_2FA,
  ROUTE_SIGN_IN_2FA_ADMIN,
  ROUTE_SIGN_IN_ADMIN,
} from "@/constants/routes";
import { useAuthentication } from "@/composables/useAuthentication";
import authRoutes from "@/router/routes/authRoutes";
import investorRoutes from "@/router/routes/investorRoutes";
import errorRoutes from "@/router/routes/errorRoutes";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: ROUTE_SIGN_IN,
  },
  ...adminRoutes,
  ...authRoutes,
  ...investorRoutes,
  ...errorRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { isAuthenticated, isAdmin, restoreUser } = useAuthentication();
  const authRoutes = [
    ROUTE_SIGN_IN,
    ROUTE_SIGN_IN_ADMIN,
    ROUTE_SIGN_IN_2FA,
    ROUTE_SIGN_IN_2FA_ADMIN,
    ROUTE_RESET_PASSWORD,
    ROUTE_RESET_PASSWORD_ADMIN,
    ROUTE_CHANGE_PASSWORD,
    ROUTE_FINALISE_ACCOUNT,
  ];

  if (!authRoutes.includes(to.path) && to.meta.admin) await restoreUser(true);

  /**
   * Route is protected when we don't have a token.
   * User should sign in before proceeding.
   */
  if (!authRoutes.includes(to.path) && !isAuthenticated.value)
    next({ path: to.meta.admin ? ROUTE_SIGN_IN_ADMIN : ROUTE_SIGN_IN });

  /**
   * Route is protected for the Admin and investor environment.
   * Proceed to designated home page when user wants to cross environment.
   */
  if (to.meta.admin && !isAdmin.value) next({ path: ROUTE_HOME_INVESTORS });
  if (to.meta.investor && isAdmin.value) next({ path: ROUTE_HOME_ADMIN });

  /**
   * User is already signed in and trying to navigate to the sign-in page.
   * In that case we'll redirect them to the home page.
   * Only for investor because Admin is already caught.
   */
  if (authRoutes.includes(to.path) && isAuthenticated.value && !to.meta.admin)
    next({ path: isAdmin ? ROUTE_HOME_ADMIN : ROUTE_HOME_INVESTORS });

  next();
});

export default router;
