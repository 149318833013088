import { RouteRecordRaw } from "vue-router";
import {
  ROUTE_CHANGE_PASSWORD,
  ROUTE_FINALISE_ACCOUNT,
  ROUTE_RESET_PASSWORD,
  ROUTE_RESET_PASSWORD_ADMIN,
  ROUTE_SIGN_IN,
  ROUTE_SIGN_IN_2FA,
  ROUTE_SIGN_IN_2FA_ADMIN,
  ROUTE_SIGN_IN_ADMIN,
} from "@/constants/routes";
import { ROLES_ADMIN } from "@/constants/roles";

const routes: Array<RouteRecordRaw> = [
  {
    path: ROUTE_SIGN_IN,
    component: () => import("../../views/Authentication/SignInView.vue"),
    meta: {
      common: true,
    },
  },
  {
    path: ROUTE_SIGN_IN_ADMIN,
    component: () => import("../../views/Authentication/SignInView.vue"),
    meta: {
      common: true,
      prefix: ROLES_ADMIN,
    },
  },
  {
    path: ROUTE_SIGN_IN_2FA,
    component: () => import("../../views/Authentication/SignIn2FAView.vue"),
    meta: {
      common: true,
    },
  },
  {
    path: ROUTE_SIGN_IN_2FA_ADMIN,
    component: () => import("../../views/Authentication/SignIn2FAView.vue"),
    meta: {
      common: true,
      prefix: ROLES_ADMIN,
    },
  },
  {
    path: ROUTE_RESET_PASSWORD,
    component: () => import("../../views/Authentication/ResetPasswordView.vue"),
    meta: {
      common: true,
    },
  },
  {
    path: ROUTE_RESET_PASSWORD_ADMIN,
    component: () => import("../../views/Authentication/ResetPasswordView.vue"),
    meta: {
      common: true,
      prefix: ROLES_ADMIN,
    },
  },
  {
    path: ROUTE_CHANGE_PASSWORD,
    component: () =>
      import("../../views/Authentication/ChangePasswordView.vue"),
    meta: {
      common: true,
    },
  },
  {
    path: ROUTE_FINALISE_ACCOUNT,
    component: () =>
      import("../../views/Authentication/FinaliseAccountView.vue"),
    meta: {
      common: true,
    },
  },
];

export default routes;
