// Auth
export const API_AUTH_LOGIN = "/users/login";
export const API_AUTH_LOGIN_ADMIN = "/users/admin/login";
export const API_AUTH_USER = "/users";
export const API_AUTH_USER_QRCODE = "/users/generate-qr-code";
export const API_AUTH_USER_VERIFY_OTP = "/users/verify-otp";
export const API_AUTH_RESET_PASSWORD = "/users/reset-password";
export const API_AUTH_CHANGE_PASSWORD = "/users/change-password";
export const API_AUTH_VERIFY_TOKEN = "/users/verify-token";

// Accounts
export const API_ACCOUNTS = "/accounts";
export const API_ACCOUNTS_AVATAR = "/accounts/avatar";
export const API_ACCOUNTS_AVATAR_UPLOAD = "/accounts/avatar/upload";

// Investments
export const API_INVESTMENTS = "/investments";
export const API_INVESTMENTS_CREATE = "/investments/create";
export const API_INVESTMENTS_REDEMPTION_CREATE = "/investments/redemption/create";
export const API_INVESTMENTS_TOTAL_HOLDINGS = "/investments/total-holdings";
export const API_INVESTMENTS_LAST_SUBSCRIPTION = "/investments/last-subscription";
export const API_INVESTMENTS_PERFORMANCE_STATS = "/investments/performance-stats";
export const API_INVESTMENTS_RANGE = "/investments/range";

// Notifications
export const API_NOTIFICATIONS = "/notifications";
export const API_NOTIFICATIONS_OPENED = "/notifications/opened";

// Scrive
export const API_SCRIVE_FILES = "/scrive/get-all-files";
export const API_SCRIVE_FILE_DOWNLOAD = "/scrive/file/download";
export const API_SCRIVE_KYC_DOCUMENT_UPLOAD = "/scrive/kyc/document/upload";
export const API_SCRIVE_KYC_DOCUMENT_APPROVE = "/scrive/kyc/document/approve";
export const API_SCRIVE_KYC_DOCUMENT_DELETE = "/scrive/kyc/document/delete";
export const API_SCRIVE_KYC_DOCUMENTS = "/scrive/kyc/documents";
export const API_SCRIVE_ATTACHMENT_APPROVE = "/scrive/attachment/approve";
export const API_SCRIVE_ATTACHMENT_DELETE = "/scrive/attachment/delete";

// Admin
export const API_INVESTMENT = "admin/investment";
export const API_REDEMPTION = "admin/redemption";
export const API_ADMIN_ALL_INVESTORS = "/admin/all-investors";
export const API_ADMIN_NEW_INVESTORS = "/admin/new-investors";
export const API_ADMIN_NEW_SUBSCRIPTIONS = "/admin/new-subscriptions";
export const API_ADMIN_ALL_SUBSCRIPTIONS = "/admin/all-subscriptions";
export const API_ADMIN_TOTAL_SUBSCRIPTIONS = "/admin/total-subscriptions";
export const API_ADMIN_TOTAL_REDEMPTIONS = "/admin/total-redemptions";
export const API_ADMIN_ALL_SUBSCRIPTIONS_REDEMPTIONS = "/admin/note-price/investments-redemptions";
export const API_ADMIN_NEW_REDEMPTIONS = "/admin/new-redemptions";
export const API_ADMIN_ALL_REDEMPTIONS = "/admin/all-redemptions";
export const API_ADMIN_APPROVE_INVESTOR = "/admin/approve-investor";
export const API_ADMIN_APPROVE_INVESTMENT_TRANSFER = "/admin/approve-investment-transfer";
export const API_ADMIN_INVESTMENT_SET_DEALING_DATE = "/admin/subscription/dealing-date";
export const API_ADMIN_REDEMPTION_SET_DEALING_DATE = "/admin/redemption/dealing-date";
export const API_ADMIN_APPROVE_REDEMPTION = "/admin/approve-redemption";
export const API_ADMIN_APPROVE_INVESTMENTS = "/admin/approve-investments";
export const API_ADMIN_INVESTOR_ACCOUNT = "/admin/investor";
export const API_ADMIN_COMPANY_STORE = "/admin/companies";
export const API_ADMIN_RESET_TWO_FACTOR_AUTHENTICATION = "/admin/customers/reset-2fa";
export const API_ADMIN_NOTE_PRICES = "/admin/note-prices";
export const API_ADMIN_NOTE_PRICES_CREATE = "/admin/note-prices/create";
export const API_ADMIN_NOTE_PRICES_CREATE_ALLOW = "/admin/note-prices/create/allow";
export const API_ADMIN_NOTE_PRICES_FIRST_LAST = "/admin/note-prices/first-and-last";
export const API_ADMIN_NOTE_PRICES_MISSING = "/admin/note-prices/missing";
export const API_ADMIN_REPORTS_UPLOAD = "/admin/reports/upload";
export const API_ADMIN_REPORTS_DELETE = "/admin/reports/delete";
export const API_ADMIN_REPORTS = "/admin/reports";
export const API_ADMIN_REPORTS_PERSONAL_GENERATE = "/admin/reports/generate/personal-report";
export const API_ADMIN_REPORTS_ON_HOLDERS_GENERATE = "/admin/reports/generate/note-holders";
export const API_ADMIN_REPORTS_PERSONAL_DONWLOAD =
  "/admin/reports/generate/personal-report/download";
export const API_ADMIN_REPORTS_NOTES_PREVIEW = "/admin/allotment-of-notes/report/preview";
export const API_ADMIN_REPORTS_PERSONAL_PUBLISH = "/admin/reports/generate/personal-report/publish";
export const API_ADMIN_NOTIFICATIONS = "admin/notifications";
export const API_ADMIN_NOTIFICATIONS_DELETE = "admin/notifications/delete";
export const API_ADMIN_CUSTOMERS_INDEX = "/admin/customers";
export const API_ADMIN_CUSTOMERS_INDEX_ARCHIVED = "/admin/customers/archived";
export const API_ADMIN_CUSTOMER_STORE = "/admin/customers";
export const API_ADMIN_COMPANIES_INDEX = "/admin/companies";
export const API_ADMIN_COMPANIES_INDEX_ARCHIVED = "/admin/companies/archived";
export const API_ADMIN_INVESTOR_DELETE = "/admin/delete-investor";
export const API_ADMIN_SUBSCRIPTION_CANCEL = "/admin/cancel-subscription";
export const API_ADMIN_REDEMPTION_CANCEL = "/admin/cancel-redemption";
export const API_ADMIN_AUDITS_INVESTOR = "/admin/audits/investor";
export const API_ADMIN_PRIVATE_DOCUMENT = "/admin/documents/private";
export const API_ADMIN_PRIVATE_DOCUMENT_DOWNLOAD = "/admin/documents/private/download";
