import { RouteRecordRaw } from "vue-router";
import {
  ROUTE_ADMIN_CONTROL_PANEL,
  ROUTE_ADMIN_DASHBOARD,
  ROUTE_ADMIN_INVESTORS,
  ROUTE_ADMIN_INVESTORS_PROFILE,
  ROUTE_ADMIN_INVESTORS_PROFILE_NOTIFICATIONS,
} from "@/constants/routes";

const routes: Array<RouteRecordRaw> = [
  {
    path: ROUTE_ADMIN_DASHBOARD,
    component: () => import("../../views/Admin/DashboardView.vue"),
    meta: {
      admin: true,
    },
  },
  {
    path: ROUTE_ADMIN_INVESTORS,
    component: () => import("../../views/Admin/InvestorsView.vue"),
    meta: {
      admin: true,
    },
  },
  {
    path: `${ROUTE_ADMIN_INVESTORS_PROFILE}/:id`,
    component: () =>
      import("../../views/Admin/InvestorsProfile/ProfileView.vue"),
    meta: {
      admin: true,
    },
  },
  {
    path: `${ROUTE_ADMIN_INVESTORS_PROFILE_NOTIFICATIONS}/:id`,
    component: () =>
      import("../../views/Admin/InvestorsProfile/NotificationsView.vue"),
    meta: {
      admin: true,
    },
  },
  {
    path: ROUTE_ADMIN_CONTROL_PANEL,
    component: () => import("../../views/Admin/ControlPanelView.vue"),
    meta: {
      admin: true,
    },
  },
];

export default routes;
