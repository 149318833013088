import { createApp } from "vue";
import App from "./App.vue";

import { IonicVue } from "@ionic/vue";

// Composables
import { useAuthentication } from "@/composables/useAuthentication";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

import "@/theme/main.scss";

// i18n
import { createI18n } from "vue-i18n";
import en from "@/locales/en";

// PrimeVue
import PrimeVue from "primevue/config";
import "primevue/resources/themes/saga-green/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.scss";

// PrimeVue global components
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";

const messages = {
  en: en,
};
const i18n = createI18n({
  legacy: false,
  locale: "en", // set locale
  fallbackLocale: "en", // set fallback locale
  messages, // set locale messages
});

const { restoreUser } = useAuthentication();
restoreUser().then(() => {
  import("./router").then((router: any) => {
    const app = createApp(App)
      .use(IonicVue)
      .use(router.default)
      .use(i18n)
      .use(PrimeVue)
      .use(ConfirmationService)
      .use(ToastService)
      .component("InputText", InputText)
      .component("Button", Button);

    router.default.isReady().then(() => {
      app.mount("#app");
    });
  });
});
