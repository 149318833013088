// Auth
export const ROUTE_SIGN_IN = "/login";
export const ROUTE_SIGN_IN_ADMIN = "/admin/login";
export const ROUTE_SIGN_IN_2FA = "/verification";
export const ROUTE_SIGN_IN_2FA_ADMIN = "/admin/verification";
export const ROUTE_RESET_PASSWORD = "/reset-password";
export const ROUTE_RESET_PASSWORD_ADMIN = "/admin/reset-password";
export const ROUTE_CHANGE_PASSWORD = "/change-password";
export const ROUTE_FINALISE_ACCOUNT = "/finalise-acccount";

// Investors
export const ROUTE_INVESTOR_INFO = "/investor/info";
export const ROUTE_INVESTOR_WALLET = "/investor/portfolio";
export const ROUTE_INVESTOR_REPORTS = "/investor/reports";
export const ROUTE_INVESTOR_ACCOUNT = "/investor/my-account";
export const ROUTE_INVESTOR_INBOX = "/investor/inbox";

// Admin
export const ROUTE_ADMIN_DASHBOARD = "/admin/dashboard";
export const ROUTE_ADMIN_INVESTORS = "/admin/investors";
export const ROUTE_ADMIN_CONTROL_PANEL = "/admin/control-panel";
export const ROUTE_ADMIN_INVESTORS_PROFILE = "/admin/investors/profile";
export const ROUTE_ADMIN_INVESTORS_PROFILE_NOTIFICATIONS =
  "/admin/investors/profile/notifications";

// Home
export const ROUTE_HOME_INVESTORS = ROUTE_INVESTOR_INFO;
export const ROUTE_HOME_ADMIN = ROUTE_ADMIN_DASHBOARD;

// Common
export const ROUTE_PAGE_NOT_FOUND = "/page-not-found";
export const ROUTE_PAGE_FORBIDDEN = "/forbidden";
