import { RouteRecordRaw } from "vue-router";
import { ROUTE_PAGE_FORBIDDEN } from "@/constants/routes";

const routes: Array<RouteRecordRaw> = [
  {
    path: ROUTE_PAGE_FORBIDDEN,
    component: () => import("@/views/PageForbiddenView.vue"),
    meta: {
      common: true,
    },
  },
  // will match everything and put it under `$route.params.pathMatch`
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/views/PageNotFoundView.vue"),
    meta: {
      common: true,
    },
  },
];

export default routes;
