import { RouteRecordRaw } from "vue-router";
import {
  ROUTE_INVESTOR_ACCOUNT,
  ROUTE_INVESTOR_INBOX,
  ROUTE_INVESTOR_INFO,
  ROUTE_INVESTOR_REPORTS,
  ROUTE_INVESTOR_WALLET,
} from "@/constants/routes";

const routes: Array<RouteRecordRaw> = [
  {
    path: ROUTE_INVESTOR_INFO,
    component: () => import("@/views/Investors/DashboardView.vue"),
    meta: {
      investor: true,
    },
  },
  {
    path: ROUTE_INVESTOR_WALLET,
    component: () => import("@/views/Investors/PortfolioView.vue"),
    meta: {
      investor: true,
    },
  },
  {
    path: ROUTE_INVESTOR_REPORTS,
    component: () => import("@/views/Investors/ReportsView.vue"),
    meta: {
      investor: true,
    },
  },
  {
    path: ROUTE_INVESTOR_ACCOUNT,
    component: () => import("@/views/Investors/AccountView.vue"),
    meta: {
      investor: true,
    },
  },
  {
    path: ROUTE_INVESTOR_INBOX,
    component: () => import("@/views/Investors/InboxView.vue"),
    meta: {
      investor: true,
    },
  },
];

export default routes;
