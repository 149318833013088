import vanillaAxios, { AxiosError } from "axios";
import applyCaseMiddleware from "axios-case-converter";
import { BASE_URL } from "@/constants/env";
import {
  API_AUTH_CHANGE_PASSWORD,
  API_AUTH_LOGIN,
  API_AUTH_RESET_PASSWORD,
  API_AUTH_USER,
  API_AUTH_USER_QRCODE,
  API_AUTH_USER_VERIFY_OTP,
  API_AUTH_VERIFY_TOKEN,
} from "@/constants/api";
import { computed, reactive, toRefs } from "vue";
import { useAxios } from "@/composables/useAxios";
import { Preferences } from "@capacitor/preferences";
import { ROUTE_SIGN_IN, ROUTE_SIGN_IN_ADMIN } from "@/constants/routes";
import { ROLES_ADMIN, ROLES_INVESTOR } from "@/constants/roles";
import { useRoute } from "vue-router";

export interface AuthData {
  user: any;
  credentials: any;
  role: string | null;
  token: string | null;
  initTwoFactorAuthentication: boolean;
}

const authData = reactive<AuthData>({
  user: null,
  credentials: null,
  role: null,
  token: null,
  initTwoFactorAuthentication: false,
});

const axiosInstance = applyCaseMiddleware(vanillaAxios.create({ baseURL: BASE_URL }));
const {
  setToken: addTokenToAxiosInstance,
  removeToken: removeTokenFromAxiosInstance,
  setAdminHeader: addAdminHeaderToAxiosInstance,
  axios,
} = useAxios();

export function useAuthentication() {
  const route = useRoute();

  const axios2faInstance = applyCaseMiddleware(
    vanillaAxios.create({
      baseURL: BASE_URL,
      headers: { Authorization: `Bearer ${authData.token}` },
    })
  );

  const storeUserCredentials = async (token: string, user: any) => {
    authData.token = token;
    authData.user = user;
    authData.role = isAdminPrefix.value ? ROLES_ADMIN : ROLES_INVESTOR;

    await Preferences.set({ key: "token", value: token });
  };

  const signIn = (formData: any) => {
    const config = isAdminPrefix.value ? { headers: { admin: isAdminPrefix.value } } : {};
    return axiosInstance
      .post(API_AUTH_LOGIN, formData, config)
      .then(({ data }: any) => {
        authData.initTwoFactorAuthentication = data.initTwoFactorAuthentication;
        authData.credentials = {
          username: formData.username,
          password: formData.password,
        };
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          throw new AxiosError(error);
        }
        // Todo: log error
        console.error("signIn error");
      });
  };

  const signOut = () => {
    const route = isAdminPrefix.value || isAdmin.value ? ROUTE_SIGN_IN_ADMIN : ROUTE_SIGN_IN;
    removeTokenFromAxiosInstance();
    purgeUserCredentials();
    window.location.href = route;
  };

  const restoreUser = async (isAdminRequest = false): Promise<any> => {
    const { value: token } = await Preferences.get({ key: "token" });
    if (token) {
      addTokenToAxiosInstance(token);
      if (isAdminRequest) addAdminHeaderToAxiosInstance(isAdminRequest);

      return axios.value
        .get(API_AUTH_USER)
        .then(({ data }) => {
          storeUserCredentials(token, data.user);
          if (isAdminRequest) authData.role = ROLES_ADMIN;
        })
        .catch(() => {
          return Promise.resolve();
        })
        .finally(() => {
          return Promise.resolve();
        });
    } else {
      return Promise.resolve();
    }
  };

  const purgeUserCredentials = async () => {
    authData.token = null;
    authData.role = null;
    authData.user = null;

    await Preferences.remove({ key: "token" });
  };

  const getQrCode = () => {
    return axios2faInstance
      .post(API_AUTH_USER_QRCODE, { credentials: authData.credentials })
      .then(({ data }) => {
        return data;
      });
  };

  const verifyOTP = (oneTimePassword: string) => {
    const config = isAdminPrefix.value ? { headers: { admin: isAdminPrefix.value } } : {};
    return axios2faInstance
      .post(
        API_AUTH_USER_VERIFY_OTP,
        { oneTimePassword: oneTimePassword, credentials: authData.credentials },
        config
      )
      .then(async ({ data }: any) => {
        storeUserCredentials(data.token, data.user);
        addTokenToAxiosInstance(data.token);
        authData.credentials = null;
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          throw new AxiosError(error);
        }
        // Todo: log error
        console.log("log error");
      });
  };

  const resetPassword = (username: string) => {
    return axiosInstance.post(API_AUTH_RESET_PASSWORD, { username }).catch(() => {
      console.log("log error");
    });
  };

  const changePassword = (formData: any) => {
    return axiosInstance.post(API_AUTH_CHANGE_PASSWORD, formData);
  };

  const verifyFinaliseAccountToken = (token: string) => {
    return axiosInstance.post(API_AUTH_VERIFY_TOKEN, { token }).catch(() => {
      signOut();
    });
  };

  const isAuthenticated = computed((): boolean => !!authData.token && !!authData.user);
  const isAdmin = computed((): boolean => authData.role === ROLES_ADMIN);
  // Todo: fix isAdmin KICK THEM OUT
  const isAdminPrefix = computed((): boolean => route?.meta?.prefix === ROLES_ADMIN);

  return {
    signIn,
    signOut,
    restoreUser,
    getQrCode,
    verifyOTP,
    resetPassword,
    changePassword,
    verifyFinaliseAccountToken,
    isAuthenticated,
    isAdmin,
    isAdminPrefix,
    ...toRefs(authData),
  };
}
